<template>
    <div>
        <base-header :base-title="$t('module.coupon_usages')"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="$t('module.coupon_usages')"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <template
                            v-if="checkPermission('LoyaltyTransactionCouponResource.POST.store')">
                            &nbsp;&nbsp;
                                <base-button-modal
                                :button-text="$t('coupons.add_coupon_usage')"
                                button-icon="flaticon2-add-1"
                                :button-name="modalName"
                                :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
            </div>

            <div class="kt-portlet__body">
                <crud-table
                ref="crudTable"
                :table-options="tableOptions"
                :repository="repository"
                :permission="permission"
                :event="fetch"
                @on-click-edit="onClickEdit"
                @on-click-delete="onClickDelete"
                @on-click-view="onClickView"
                ></crud-table>
            </div>

            <form-modal v-if="isShowModal" @event="fetch"></form-modal>
        </div>
    </div>
</div>
</template>
<script>
    import BaseHeader from "./../../../components/_base/BaseHeader";
    import BaseTitle from "./../../../components/_base/BaseTitle";
    import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
    import CheckPermission from "./../../../services/checkPermission.service";
    import CrudTable from "./../base/crud-table/crudTable";
    import FormModal from "./FormModal";
    import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
    import { mapState, mapMutations, mapActions } from "vuex";
    import CouponUsagesModel from '../../model/coupon-usages-model';
    const transactionCouponRepository = RepositoryFactory.get("transactionCouponRepository");
    const checkPermission = new CheckPermission();
    import ColumnImage from '@/components/_general/columnTableLoyalty/ColumnImageCoupons.vue'
    import ColumnCode from '@/components/_general/columnTableLoyalty/ColumnCode.vue'
    import ColumnTransactionDate from '@/components/_general/columnTableLoyalty/ColumnTransactionDate.vue'

    export default {
        components:{ BaseHeader, BaseTitle, BaseButtonModal, CrudTable, FormModal },
        methods:{
            ...mapMutations({
                refreshData: 'Crud/REFRESH_TABLE',
                setRepository: 'Crud/SET_REPOSITORY',
                showModal:'Crud/SHOW_MODAL',
                hideModal:'Crud/HIDE_MODAL',
                setModal: 'Crud/SET_MODAL',
                setEditData: 'Crud/SET_EDIT_DATA',
                setViewData: 'Crud/SET_VIEW_DATA',
            }),
            checkPermission(method) {
                return checkPermission.isCanAccess(method);
            },
            fetch(){
                this.refreshData(true);
            },
            onClickView(data) {
                this.$store.commit("Crud/SET_VIEW_DATA", data);
                this.showModal();
            },
            onClickEdit(data) {
                this.$store.commit("Crud/SET_EDIT_DATA", data);
                this.showModal()


            },
            onClickDelete(data) {
                this.$store.dispatch("Crud/deleteById", {
                    deleteRepository: transactionCouponRepository.delete,
                    id: data.id,
                    name: data.name_on_card
                });
            },
        },
        computed:{
            ...mapState("Crud", {
                modalName: state => state.modalName,
                isLoaded: state => state.isLoaded,
                table: state => state.table,
                isShowModal: state => state.isShowModal,
                showModalMode: state => state.showModalMode,
                shouldRefreshTable: state => state.shouldRefreshTable,
            })
        },
        watch:{
            shouldRefreshTable: function(newValue, oldValue) {
                if (newValue === true) {
                    this.$refs.crudTable.refreshTable();
                }
            }
        },
        data(){
            var vx = this;
            return {
                repository: transactionCouponRepository,
                    permission: {
                        view: true,
                        edit: true,
                        delete: true,
                    },
                    tableOptions: {
                        name: "CRUD_TABLE",
                        columns: [ 'id', 'rTransactionDate', 'name_on_card', 'rCode', 'title', 'subtitle', 'point' , 'rImageRender','actions'],
                        sortable: ['id', 'rTransactionDate', 'name_on_card', 'rCode', 'title', 'subtitle', 'point'],
                        orderBy: { column: 'id', ascending:false },
                        headings: {
                            id:'ID',
                            rTransactionDate:this.$t('coupons.transaction_date'),
                            name_on_card:this.$t('member.name_on_card'),
                            rCode:this.$t('coupons.coupon_code'),
                            title:this.$t('common.title'),
                            rImageRender:this.$t('common.gambar'),
                            subtitle:this.$t('common.subJudul'),
                            point:this.$t('common.point')
                        },
                        customColumn: {
                            rCode:'code',
                            rTransactionDate:'transaction_date',
                            rImageRender: 'image_path'
                        },
                        columnsFilter: [
                            {key:'id',type:'number'},
                            {key:'rTransactionDate',type:'date'},
                            {key:'name_on_card',type:'string'},
                            {key:'rCode',type:'string'},
                            {key:'title',type:'string'},
                            {key:'subtitle',type:'string'},
                            {key:'point',type:'number'}
                        ],
                        templates: {
                            rImageRender: ColumnImage,
                            rCode: ColumnCode,
                            rTransactionDate:ColumnTransactionDate

                        },

                    }
            }
        },
        created(){
            this.setRepository(transactionCouponRepository)
            this.setModal("CouponUsagesModal")
            this.hideModal();
        },
    }
</script>
