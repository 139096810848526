<template>
    <div @click="focusMemberID"
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ this.couponUsagesModel.id ? (this.modeView ? "" : "Ubah"): $t('button.add') }} {{ $t("module.coupon_usages") }}</h5>
                </div>
                <form @submit.prevent="true;">
                    <div class="modal-body">
                        <input
                            type="hidden"
                            class="form-control"
                            v-model.number="couponUsagesModel.id"
                        />
                        <div class="row">
                            <div class="col-md-12 form-group" >
                                <label for="">{{ $t('coupons.coupon_code') }} : </label>
                                <div class="input-group">
                                    <!-- @blur="getCouponInfo" -->
                                    <input v-model="scan_code"
                                    class="form-control" :disabled="modeView"
                                    ref="couponCode"
                                    title="Fokuskan kursor sebelum Scan Kode"
                                    v-validate="'required'"
                                    :readonly="isReadOnly"
                                    @keypress="onCouponTyped"
                                    :class="{'is-invalid': errors.has('code')}"
                                    name="code" :data-vv-as="$t('coupons.coupon_code')"
                                    />
                                    <div class="input-group-append">
                                        <button :disabled="isEraseDisabled" :title="$t('button.delete')" @click="resetCouponCode" type="button" class="btn btn-default"><i class="fa fa-eraser"></i></button>
                                        <button :disabled="isEraseDisabled" :title="$t('button.delete')" @click="getCouponInfo" type="button" class="btn btn-default"><i class="fa fa-search"></i></button>
                                    </div>
                                   
                                </div>
                                 <div v-show="errors.first('code')" class="custom-invalid-feedback" >{{ errors.first('code') }}</div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">{{ $t('common.title') }}:</label>
                                    <input v-model="couponUsagesModel.title" v-validate="'required'" class="form-control" :class="{'is-invalid':errors.has('title')}" ref="transactionCode" disabled="disabled" name="title" :data-vv-as="$t('common.title')"/>
                                    <div v-show="errors.first('title')" class="custom-invalid-feedback" >{{ errors.first('title') }}</div>
                                </div>
                                <div class="form-group">
                                    <label for="">{{ $t('common.subJudul') }}:</label>
                                    <input v-model="couponUsagesModel.subtitle" v-validate="'required'" class="form-control" :class="{'is-invalid':errors.has('subtitle')}" ref="transactionCode" disabled="disabled" name="subtitle" :data-vv-as="$t('common.subJudul')"/>
                                    <div v-show="errors.first('subtitle')" class="custom-invalid-feedback" >{{ errors.first('subtitle') }}</div>
                                </div>
                                <div class="form-group">
                                    <label for="">{{ $t('coupons.exchange_points') }}:</label>
                                    <input v-model="couponUsagesModel.point" v-validate="'required'" class="form-control" :class="{'is-invalid':errors.has('point')}" ref="transactionCode" disabled="disabled" name="point" :data-vv-as="$t('coupons.exchange_points')"/>
                                    <div v-show="errors.first('point')" class="custom-invalid-feedback" >{{ errors.first('point') }}</div>
                                </div>
                                <div class="form-group">
                                    <img :src="getPathImage" class="img img-responsive"/>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">{{ $t('member.member_id') }}:</label>
                                    <input v-model="couponUsagesModel.member_id" v-validate="'required'" class="form-control" :class="{'is-invalid':errors.has('member_id')}" ref="transactionCode" disabled="disabled" name="member_id" :data-vv-as="$t('member.member_id')"/>
                                    <div v-show="errors.first('member_id')" class="custom-invalid-feedback" >{{ errors.first('member_id') }}</div>
                                </div>
                                <div class="form-group">
                                    <label for="">{{ $t('member.member_number') }}:</label>
                                    <input v-model="couponUsagesModel.member_number" v-validate="'required'" class="form-control" :class="{'is-invalid':errors.has('member_number')}" ref="transactionCode" disabled="disabled" name="member_number" :data-vv-as="$t('member.member_number')"/>
                                    <div v-show="errors.first('member_number')" class="custom-invalid-feedback" >{{ errors.first('member_number') }}</div>
                                </div>
                                <div class="form-group">
                                    <label for="">{{ $t('member.name_on_card') }}:</label>
                                    <input v-model="couponUsagesModel.name_on_card" class="form-control" ref="transactionCode" disabled="disabled"
                                        name="name_on_card" :data-vv-as="$t('member.name_on_card')"/>
                                    <div v-show="errors.first('name_on_card')" class="custom-invalid-feedback" >{{ errors.first('name_on_card') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="hideModal()" >{{ $t('button.close') }}</button>
                        <button v-if="!modeView" type="button" :disabled="couponUsagesModel.code == null || errors.has('code')" @click="submitData" class="btn btn-default btn-bold btn-upper btn-font-md">{{ $t('button.save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import CouponUsagesModel from "./../../model/coupon-usages-model";
import { isNullOrUndefined } from "util";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import { mapState, mapMutations, mapActions } from "vuex";
import MemberRepository from '../../repositories/MemberRepository';
import CouponsRepository from '../../repositories/CouponsRepository';
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import TransactionCouponRepository from '../../repositories/TransactionCouponRepository';
import BlockUI from "./../../../components/_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

VeeValidate.setMode('passive');

export default {
    components:{},

    data(){
        return {
            isReadOnly:false,
            scan_code:null,
            couponUsagesModel:new CouponUsagesModel(),
            urlImage:  process.env.VUE_APP_API_URL + "/gallery/api/image/alt"
        }
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode,
            isShowModal: state => state.isShowModal,
        }),

        isEraseDisabled(){
            return this.modeView || isNullOrUndefined(this.scan_code);
        },
        getPathImage(){
            let result = this.couponUsagesModel.image_path;
            if(result){
                let slash = this.couponUsagesModel.image_path.indexOf('/')===0? '':'/';
                result = this.couponUsagesModel.image_path.indexOf('http')
                    === 0 ? this.couponUsagesModel.image_path : this.urlImage + slash + this.couponUsagesModel.image_path;
            }
            return result;
        },
        modeEdit(){
            return (this.dataEdit && this.dataEdit.id) || this.modeView;
        },
        modeView(){
            return this.showModalMode == 'V';
        },
    },
    methods:{
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            showModal: "Crud/SHOW_MODAL",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),
        clearData: function () {
            $("#" + this.modalName).modal("hide");
            this.$emit("update:showModal", false);
        },

        resetCouponCode(isFocus){
            let id = this.couponUsagesModel.id;
            this.couponUsagesModel = new CouponUsagesModel;
            this.couponUsagesModel.id = id;
            this.scan_code = null;
            if(false!==isFocus){
                this.isReadOnly = false;
                this.$refs.couponCode.focus();
                this.errors.remove('code');
            }
        },
        onCouponTyped(pressedButton){
            let key = pressedButton.key;
            this.errors.remove('code');

            if("Enter"==key){
                this.getCouponInfo();
            }
        },
        getCouponInfo(){
            if(
                this.couponUsagesModel.id != null
                && this.couponUsagesModel.name_on_card != null
                && this.couponUsagesModel.code != null
                && this.couponUsagesModel.member_id != null
                && this.couponUsagesModel.point != null
            ){
                return;
            }

            if(this.scan_code == null){
                this.errors.add({field: 'code', msg: this.$t('sentences.invalid_coupon_code'), rule: "required"});
                this.focusMemberID();
                return;
            }
            let data = this.scan_code.split('|');
            if(data.length!==2){
                this.errors.add({field: 'code', msg: this.$t('sentences.invalid_coupon_code'), rule: "required"});
                return;
            }
            this.isReadOnly=true;
            let couponCode = data[0];
            let memberNumber = data[1];
            blockUI.blockModal();
            let coba = Promise.all([this.getMemberData(memberNumber), this.getCouponData(couponCode)]).then( result => {
                let memberData = result[0].data;
                let couponData = result[1].data;

                let previosId = this.couponUsagesModel.id;
                this.couponUsagesModel = Object.assign({},{
                    id:previosId,
                    member_number:memberData.member_no,
                    member_id:memberData.id,
                    name_on_card:memberData.name_on_card,
                    code:couponData.code,
                    title:couponData.title,
                    subtitle:couponData.subtitle,
                    image_path:couponData.image_path,
                    point:couponData.point
                });
                if(memberData.point< couponData.point){
                    let msgError = this.$t('sentences.not_enugh_point_member');
                    let lessPoint = parseInt(couponData.point) -  parseInt(memberData.point);
                    msgError+=` ( ${this.$t('member.point')}: ${memberData.point}, ${this.$t('common.required')}: ${couponData.point}, ${this.$t('common.less')}: ${lessPoint} )`;
                    this.errors.add({field: 'code', msg:msgError , rule: "required"});
                }

                var expired_date = moment(couponData.expired_date)
                var now = moment();
                if(expired_date < now){
                    let msgError = this.$t('sentences.coupon_expired');
                    this.errors.add({field: 'code', msg:msgError , rule: "required"});
                }
                blockUI.unblockModal();
            }).catch( e => {
                this.errors.add({field: 'code', msg: this.getServerError(e), rule: "required"});
                blockUI.unblockModal();
            });

        },
        getCouponData(couponCode){
            return CouponsRepository.couponByCode(couponCode)
        },
        getMemberData(memberNumber){
            return MemberRepository.getByNumber(memberNumber)
        },
        updateData() {
            if (this.dataEdit) {
                blockUI.blockModal();
                var vx = this;
                MemberRepository.getById(this.dataEdit.member_id)
                    .then((response)=>{
                        blockUI.unblockModal();
                        let dataEdit = this.dataEdit;
                        dataEdit.member_number = response.data.member_no;
                        this.scan_code = dataEdit.code+'|'+dataEdit.member_number;
                        this.couponUsagesModel = Object.assign({}, dataEdit);
                        this.isReadOnly = true;
                    }).catch(e=>{
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Gagal!",
                            swalText: vx.getServerError(
                                e,
                                "Data Tidak Ditemukan"
                            ),
                            swalType: "error",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                                vx.hideModal();
                            }
                        });
                    });


            }

        },
        getServerError(error, defaultMsg) {
            return _.isUndefined(error.response.data.message)? defaultMsg : error.response.data.message;
        },
        focusMemberID(){
            if(
                (
                    isNullOrUndefined( this.scan_code )
                    || this.scan_code == null
                    || this.scan_code == undefined
                    || this.scan_code == ''
                )

            && this.$refs.couponCode){
                this.$refs.couponCode.focus();
                return true;
            }
            return false;
        },

        async update(payload,couponUsageId) {
                var vx = this;
                await TransactionCouponRepository.update(payload,couponUsageId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            vx.hideModal();
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Penggunaan Kupon berhasil diubah', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);

                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Penggunaan Kupon gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        async create(payload) {
            var vx = this;
            await TransactionCouponRepository.create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).
                        then((response) => {
                            vx.hideModal();
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Penggunaan Kupon berhasil ditambahkan', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                }
                            });
                        }).catch(error=>{

                        });
                    })
                    .catch(error => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Gagal!",
                            swalText: vx.getServerError(
                                error,
                                "Data Penggunaan Kupon gagal ditambahkan."
                            ),
                            swalType: "error",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    });
        },
        submitData(){
            this.$validator.validateAll().then(result => {

                if (result) {
                    var vx = this;
                    if (this.couponUsagesModel.id) {

                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.update(vx.couponUsagesModel, vx.couponUsagesModel.id);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                const data = {};
                                _.forEach(vx.couponUsagesModel, (v, k) => {
                                    if(v != null){
                                        data[k] = v;
                                    }
                                });

                                vx.create(data);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }

                    return;
                }
                else{

                }
            });
        },
    },
    mounted() {
        if(!this.modeView){
            if( isNullOrUndefined(this.scan_code && this.$refs.couponCode)){
                setTimeout(()=>{
                    this.$refs.couponCode.focus();
                },500)
            }
        }
        $("#" + this.modalName).modal();
        this.updateData();
    }
}
</script>
