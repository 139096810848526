export default function CouponUsagesModel() {
    return {
        id:null,
        transaction_date:null,
        member_number:null,
        member_id:null,
        name_on_card:null,
        code:null,
        title:null,
        subtitle:null,
        image_path:null,
        point:null,
    };
}
